import React from "react";
import {
  UserOutlined,
  FolderOpenOutlined,
  FileOutlined,
  BorderOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";

const GroupMenu = [
  {
    key: "qc_work",
    icon: <FileOutlined />,
    title: "qc_work",
  },
  {
    key: "qc",
    icon: <UserOutlined />,
    title: "qc",
  },
  {
    key: "concrete_form",
    icon: <BorderOutlined />,
    title: "concrete_form",
  },
  {
    key: "management",
    icon: <FolderOpenOutlined />,
    title: "management",
  },
  {
    key: "report",
    icon: <FileSearchOutlined />,
    title: "report",
  },
];

const reportGroup = [
  {
    path: "/qc-working-report",
    module: "report",
    sub_module: "qcworking",
    title: "qc_working_report",
    group: "report",
    breadcrumb: [],
    matching_key: "qc_working_report",
  },
  {
    path: "/concrete-report",
    module: "report",
    sub_module: "concrete",
    title: "concrete_report",
    group: "report",
    breadcrumb: [],
    matching_key: "concrete_report",
  },
  {
    path: "/supervisor-working-report",
    module: "report",
    sub_module: "supervisorworking",
    title: "supervisor_working_report",
    group: "report",
    breadcrumb: [],
    matching_key: "supervisor_working_report",
  },
  {
    path: "/mold-status-report",
    module: "report",
    sub_module: "moldstatus",
    title: "mold_status_report",
    group: "report",
    breadcrumb: [],
    matching_key: "mold_status_report",
  },
  {
    path: "/milladd-report",
    module: "report",
    sub_module: "milladd",
    title: "milladd_report",
    group: "report",
    breadcrumb: [],
    matching_key: "milladd_report",
  },
  {
    path: "/reportbysite-report",
    module: "report",
    sub_module: "reportbysite",
    title: "reportbysite_report",
    group: "report",
    breadcrumb: [],
    matching_key: "reportbysite_report",
  },
];

const managementGroup = [
  {
    path: "/user",
    module: "master-data",
    sub_module: "user",
    title: "user",
    group: "management",
    breadcrumb: [],
    matching_key: "user",
  },
  {
    path: "/usergroup",
    module: "master-data",
    sub_module: "usergroup",
    title: "usergroup",
    group: "management",
    breadcrumb: [],
    matching_key: "usergroup",
  },
  {
    path: "/permission",
    module: "master-data",
    sub_module: "permission",
    title: "permission",
    group: "management",
    breadcrumb: [],
    matching_key: "permission",
  },
  {
    path: "/concrete-collector",
    module: "master-data",
    sub_module: "concrete-collector",
    title: "concrete_collector",
    group: "management",
    breadcrumb: [],
    matching_key: "concrete_collector",
  },
];

const qcWork = [
  //   {
  //     path: "/assign-job",
  //     module: "qc-management",
  //     sub_module: "assign-job",
  //     title: "assign_job",
  //     group: "qc",
  //     breadcrumb: [],
  //     matching_key: "qc_assign_job"
  //   },
  {
    path: "/create-job",
    module: "qc-management",
    sub_module: "qc-job",
    title: "qc_job",
    group: "qc_work",
    breadcrumb: [
      {
        "create-job": [{ name: "sidebar.qc_job", url: null }],
        "create-job/create": [
          { name: "sidebar.qc_job", url: "/create-job" },
          { name: "action.create", url: null },
        ],
        "create-job/edit": [
          { name: "sidebar.qc_job", url: "/create-job" },
          { name: "action.edit", url: null },
        ],
        "create-job/detail": [
          { name: "sidebar.qc_job", url: "/create-job" },
          { name: "action.detail", url: null },
        ],
      },
    ],
    matching_key: "qc_job",
  },
  {
    path: "/calendar",
    module: "qc-management",
    sub_module: "calendar",
    title: "calendar",
    group: "qc_work",
    breadcrumb: [],
    matching_key: "qc_calendar",
  },
  {
    path: "/qc-tracking",
    module: "tracking",
    sub_module: "qc",
    title: "qc_tracking",
    group: "qc_work",
    breadcrumb: [],
    matching_key: "qc_tracking",
  },
  {
    path: "/concretedashboard",
    module: "tracking",
    sub_module: "concretedashboard",
    title: "concrete_dashboard",
    group: "qc_work",
    breadcrumb: [],
    matching_key: "qc_concretedashboard",
  },
];

const qcGroup = [
  {
    path: "/qc-data",
    module: "qc-management",
    sub_module: "qc-data",
    title: "qc_data",
    group: "qc",
    breadcrumb: [],
    matching_key: "qc_data",
  },
  {
    path: "/vacation",
    module: "qc-management",
    sub_module: "vacation",
    title: "vacation",
    group: "qc",
    breadcrumb: [],
    matching_key: "qc_vacation",
  },
  {
    path: "/vacation-calendar",
    module: "qc-management",
    sub_module: "vacation-new",
    title: "vacationCalendar",
    group: "qc",
    breadcrumb: [],
    matching_key: "qc_vacation",
  },
  {
    path: "/supervisor",
    module: "qc-management",
    sub_module: "supervisor",
    title: "supervisor",
    group: "qc",
    breadcrumb: [],
    matching_key: "qc_supervisor",
  },
  {
    path: "/qc-routine",
    module: "qc-management",
    sub_module: "qc-routine",
    title: "qc_routine",
    group: "qc",
    breadcrumb: [],
    matching_key: "qc_routine",
  },
  {
    path: "/qc-ranking",
    module: "qc-management",
    sub_module: "qc-ranking",
    title: "qc_raking",
    group: "qc",
    breadcrumb: [],
    matching_key: "qc_raking",
  },
];

// const trackingGroup = [
//   {
//     path: "/qc-tracking",
//     module: "tracking",
//     sub_module: "qc",
//     title: "qc_tracking",
//     group: "tracking",
//     breadcrumb: [],
//     matching_key: "qc_tracking"
//   },
//   {
//     path: "/form-tracking",
//     module: "tracking",
//     sub_module: "concrete-formwork",
//     title: "form_tracking",
//     group: "tracking",
//     breadcrumb: [],
//     matching_key: "concrete_formwork_tracking"
//   },
// ]

const formworkGroup = [
  {
    path: "/register-mold",
    module: "formwork",
    sub_module: "register",
    title: "register_mold",
    group: "concrete_form",
    breadcrumb: [],
    matching_key: "concrete_form",
  },
  {
    path: "/dashboard-mold",
    module: "formwork",
    sub_module: "dashboard",
    title: "dashboard_mold",
    group: "concrete_form",
    breadcrumb: [],
    matching_key: "dashboard_mold",
  },
  {
    path: "/transfer-formwork",
    module: "formwork",
    sub_module: "transfer",
    title: "transfer_formwork",
    group: "concrete_form",
    breadcrumb: [],
    matching_key: "transfer_formwork",
  },
  {
    path: "/physicalcount",
    module: "formwork",
    sub_module: "physicalcount",
    title: "physicalcount_formwork",
    group: "concrete_form",
    breadcrumb: [],
    matching_key: "physicalcount_formwork",
  },
];

const ListMenu = [
  ...managementGroup,
  ...qcGroup,
  ...qcWork,
  ...formworkGroup,
  ...reportGroup,
];

export { GroupMenu, ListMenu };
